/** @jsx jsx */
import { jsx, Flex } from "theme-ui"

const Footer = () => {
  return (
    <Flex
      as="footer"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        width: ["100%", null, "calc(100% - 300px)"],
        marginTop: 5,
        marginLeft: [0, null, 300],
        padding: 3,
        fontSize: 3,
      }}
    ></Flex>
  )
}

export default Footer

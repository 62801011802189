import { createElement } from "react"

/** @jsx jsx */
import { jsx, Flex, Text } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import icons from "../../utils/icons"

import { Link } from "gatsby"
import useName from "../hooks/use-name"
import { useResponsiveValue } from "@theme-ui/match-media"

export default function Social() {
  const screen = useResponsiveValue(["mobile", "tablet", "laptop", "desktop"])
  const name = useName()
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          socialLinks {
            name
            url
          }
        }
      }
    }
  `)
  return (
    <div
      css={{
        position: screen === "desktop" ? "fixed" : "static",
        bottom: "0",
      }}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.site.siteMetadata.socialLinks.map(social => (
          <a
            key={social.name}
            href={social.url}
            sx={{
              variant: "variant.links.nav",
              p: 1,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {createElement(icons[social.name.toLowerCase()], { size: 22 })}
          </a>
        ))}
      </Flex>
      <Text sx={{ color: "#999999", textAlign: "center" }}>
        {new Date().getFullYear()} ©{" "}
        <Link to="/" sx={{ color: "#999999", textDecoration: "none" }}>
          {name}
        </Link>
      </Text>
    </div>
  )
}

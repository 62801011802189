import React from "react"
import Header from "./header"
import Main from "./main"
import Footer from "./footer"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/keh0mph.css" />
      </Helmet>
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export default Layout

import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
  FaPinterestP,
  FaGithub,
  FaGitlab,
  FaTumblr,
  FaDeviantart,
  FaBehance,
  FaDribbble,
  Fa500Px,
  FaFlickr,
  FaVimeoV,
} from "react-icons/fa"

import { SiTiktok } from "react-icons/si"
import { FiMail } from "react-icons/fi"

const icons = {
  facebook: FaFacebookF,
  twitter: FaTwitter,
  instagram: FaInstagram,
  youtube: FaYoutube,
  linkedin: FaLinkedinIn,
  gitlab: FaGithub,
  pinterest: FaPinterestP,
  github: FaGitlab,
  tumblr: FaTumblr,
  deviantart: FaDeviantart,
  behance: FaBehance,
  dribbble: FaDribbble,
  flickr: FaFlickr,
  "500px": Fa500Px,
  tiktok: SiTiktok,
  mail: FiMail,
  vimeo: FaVimeoV,
}

export default icons
